<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list color="white" rounded>
      <v-list-item
        v-for="name in items"
        :key="name"
        :to="{ name }"
        :exact="name === 'Home'"
        color="primary"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$ml.get(name)" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="gotoTelegram">
        <v-list-item-content>
          <v-list-item-title>
            <v-icon class="pb-1 pr-2" color="#416fad">mdi-send</v-icon>TELEGRAM 
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item link @click="$ml.change('ru')" class="justify-center">
          <v-list-item-icon>
            <v-img
              class="mr-2"
              max-height="30"
              max-width="30"
              src="../../assets/ru.svg"
            ></v-img>
            RU
          </v-list-item-icon>
        </v-list-item>
        <v-list-item link @click="$ml.change('en')" class="justify-center">
          <v-list-item-icon>
            <v-img
              class="mr-2"
              max-height="30"
              max-width="30"
              src="../../assets/en.svg"
            ></v-img>
            EN
          </v-list-item-icon>
        </v-list-item>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "HomeDrawer",

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
    methods: {
    gotoTelegram: function () {
      window.open("https://t.me/syrf_official_channel");
    },
  },
};
</script>
